module.exports = {
  siteTitle: 'Fuze Empire', // <title>
  manifestName: 'Fuze Empire',
  manifestShortName: 'Fuze Empire', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Fuze Empire',
  subHeading: 'Construction • cleaning • motor retail',
  // social
  socialLinks: [
    // {
    //   style: 'brands',
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   style: 'brands',
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:tebogo@fuzeempire.co.za',
      value: 'tebogo@fuzeempire.co.za',
    },
    {
      style: 'solid',
      icon: 'fa-phone',
      name: 'Phone',
      url: 'tel:+0679214452',
      value: '067 921 4452',
    },
  ],
};
